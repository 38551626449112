import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import Slider from "react-slick";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const getInfinite = (posts, settings) => posts.length >= settings.slidesToShow;

const BlogCarousel = ({ data, settings: settingsRaw, showLogo = true }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const [settings, setSettings] = useState(settingsRaw);

  useEffect(() => {
    if (settingsRaw.infinite) {
      const newSettings = { ...settingsRaw };
      newSettings.responsive = newSettings.responsive.map(s => ({
        ...s,
        settings: { ...s.settings, infinite: getInfinite(posts, s.settings) },
      }));
      setSettings({
        ...newSettings,
        infinite: getInfinite(posts, newSettings),
      });
    } else {
      setSettings(settingsRaw);
    }
  }, [settingsRaw, posts.length, posts]);

  return (
    <Slider {...settings}>
      {posts &&
        posts.map(({ node: post }) => (
          <div className="is-parent column is-6" key={post.id}>
            <article
              className={`blog-list-item tile is-child box notification ${
                post.frontmatter.featuredpost ? "is-featured" : ""
              }`}
              style={{
                backgroundColor: "white",
              }}
            >
              <header>
                <div className="post-meta">
                  <Link
                    className="has-text-grey"
                    style={{
                      fontSize: "1.1em",
                      fontWeight: "600",
                      textDecoration: "unset",
                    }}
                    to={post.fields.slug}
                  >
                    {post.frontmatter.title}
                  </Link>
                  {/* <span> &bull; </span> */}
                  <span
                    className="subtitle is-block"
                    style={{ fontSize: "1.08em" }}
                  >
                    {post.frontmatter.date}
                  </span>
                  {showLogo ? (
                    <span className="blog-entry-logo">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.logo,
                          alt: `logo for post ${post.title}`,
                        }}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </header>
              {/* <p>
                {post.excerpt}
                <br />
                <br />
                <Link className="button" to={post.fields.slug}>
                  Keep Reading →
                </Link> 
              </p> */}
            </article>
          </div>
        ))}
    </Slider>
  );
};

BlogCarousel.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  settings: PropTypes.object.isRequired,
  showLogo: PropTypes.bool,
};

export default ({ settings, showLogo }) => (
  <StaticQuery
    query={graphql`
      query BlogCarouselQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD.MM.YYYY")
                featuredpost
                logo {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogCarousel
        data={data}
        count={count}
        settings={settings}
        showLogo={showLogo}
      />
    )}
  />
);
