import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Slider from "react-slick";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const FeatureGrid = ({ gridItems, settings }) => (
  <Slider {...settings} className="stakeholders">
    {gridItems.map(item => {
      return (
        <div key={item.linkTitle} className="column is-2">
          <section
            className="section has-text-centered p-t-lg p-b-md stakeholder-item"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="">
              <div
                style={{
                  width: "200px",
                  height: "165px",
                  display: "inline-block",
                }}
              >
                <PreviewCompatibleImage imageInfo={item} />
              </div>
            </div>
            <Link
              className="button is-rounded is-outlined is-outlined-grey"
              to={`/how-it-works#${item.linkTitle}`}
            >
              {item.linkTitle}
            </Link>
          </section>
        </div>
      );
    })}
  </Slider>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      settings: PropTypes.object.isRequired,
    }),
  ),
};

export default FeatureGrid;
