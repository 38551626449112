/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql, withPrefix } from "gatsby";
import Slider from "react-slick";
import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogCarousel from "../components/BlogCarousel";
import { AuthContext } from "../components/auth/Auth";

const sliderSettings = {
  // centerMode: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 15500,
  arrows: false,
  lazyLoad: "progressive",
  pauseOnDotsHover: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: false,
      },
    },
  ],
};

const blogSliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 15500,
  arrows: true,
  lazyLoad: false,
  pauseOnDotsHover: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: 1216,
      settings: {
        slidesToShow: 2,
        autoplay: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

const stakeholdersSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  // autoplay: true,
  // autoplaySpeed: 15500,
  arrows: true,
  lazyLoad: false,
  pauseOnDotsHover: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: 1216,
      settings: {
        slidesToShow: 2,
        autoplay: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        // arrows: false,
      },
    },
  ],
};

export const IndexPageTemplate = ({
  // title,
  // subheading,
  mainpitch,
  heading,
  description,
  intro,
}) => (
  <div>
    <div className="container">
      <div id="images-carousel" className="content">
        <div
          id="images-carousel-overlay"
          className="overlay is-size-5"
          onClick={e => {
            // use  id="images-carousel-overlay" to avoid double tap
            e.target.style.display = "none";
          }}
          onTouchStart={e => {
            e.target.style.display = "none";
          }}
        >
          <span className="has-text-centered">
            <i className="fas fa-retweet" />
            <br />
            Rotate your device
            <br />
            for a better experience
          </span>
          {/* <i class="fas fa-retweet"></i>&nbsp;Rotate your device for a better experience */}
        </div>
        <Slider {...sliderSettings} className="slider-arrows-offset">
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_1-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_1-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_1-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_1-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_2-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_2-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_2-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_2-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_3-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_3-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_3-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_3-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_4-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_4-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_4-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_4-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_5-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_5-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_5-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_5-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_6-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_6-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_6-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_6-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_7-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_7-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_7-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_7-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_8-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_8-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_8-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_8-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_9-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_9-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_9-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_9-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_10-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_10-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_10-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_10-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_11-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_11-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_11-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_11-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_12-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_12-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_12-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_12-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_13-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_13-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_13-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_13-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_14-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_14-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_14-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_14-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_15-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_15-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_15-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_15-1920w.jpg`}
          />
          <img
            srcSet={`${withPrefix("/")}img/carousel/carousel_16-768w.jpg 768w,
            ${withPrefix("/")}img/carousel/carousel_16-1023w.jpg 1023w,
            ${withPrefix("/")}img/carousel/carousel_16-1920w.jpg 1920w`}
            src={`${withPrefix("/")}img/carousel/carousel_16-1920w.jpg`}
          />
        </Slider>
      </div>
      {/* <span
        className="has-text-centered small-portrait-only"
        style={{
          marginTop: "-1rem",
          flexDirection: "column",
        }}
      >
        <span>
          <i class="fas fa-retweet"></i>&nbsp;Rotate your device for a better
          experience
        </span>
      </span> */}
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section p-t-lg" id="main-page-section-1">
          <div className="columns">
            <div className="column is-12 is-paddingless-mobile">
              <div className="content">
                <div className="columns is-widescreen main-page-pitch-block">
                  <div className="column is-6">
                    <div style={{ position: "relative", paddingTop: "56.25%" }}>
                      <iframe
                        className="youtube-fill-space"
                        src="https://www.youtube.com/embed/XzOLPiyhBoQ?rel=0"
                        width="50%"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen="allowfullscreen"
                      />
                    </div>
                  </div>
                  <div className="column is-6 has-text-left main-page-intro-text">
                    <div className="">
                      <h3 className="has-text-weight-medium is-size-3">
                        {mainpitch.title}
                      </h3>
                    </div>
                    <div className="">
                      <h4 className="subtitle has-text-weight-normal has-text-justified">
                        {mainpitch.description}
                      </h4>
                      <AuthContext.Consumer>
                        {({ currentUser }) => (
                          <>
                            {!currentUser && (
                              <span className="buttons is-centered m-t-lg">
                                <Link
                                  className="button is-rounded is-outlined is-outlined-grey is-medium"
                                  to="/register"
                                >
                                  Sign up!
                                </Link>
                              </span>
                            )}
                          </>
                        )}
                      </AuthContext.Consumer>
                    </div>
                  </div>
                </div>

                <div className="columns m-t-lg has-text-left">
                  <div className="column is-12">
                    <h3 className="has-text-weight-medium is-size-3 ">
                      {heading}
                    </h3>
                    <h4 className="subtitle has-text-weight-normal has-text-justified">
                      {description}
                    </h4>
                  </div>
                </div>
                <Features
                  gridItems={intro.blurbs}
                  settings={stakeholdersSliderSettings}
                />
                <div className="column is-12 is-paddingless-tablet p-t-xl">
                  <h3 className="has-text-weight-medium is-size-3 has-text-left">
                    Latest updates
                  </h3>
                  <div id="main-blog-carousel">
                    <BlogCarousel settings={blogSliderSettings} />
                  </div>
                  <div
                    style={{ marginTop: "1rem" }}
                    className="column is-12 has-text-centered"
                  >
                    <Link
                      className="button is-rounded is-outlined is-outlined-grey"
                      to="/blog"
                    >
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  // image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // TODO better solution would be to use flexbox, instead of absolute positioning of logo
  const adjustBlogCarousel = () => {
    // without it height adjust doesn't work on dynamic windows resize
    forceUpdate();

    const carousel = document.querySelector("#main-blog-carousel");
    const overlay = document.querySelector("#images-carousel-overlay");
    // slow network precaution
    if (carousel) {
      setTimeout(() => {
        carousel.style.height = `${
          document.querySelector("#main-blog-carousel .slick-track")
            .clientHeight
        }px`;

        overlay.style.height = `${
          document.querySelector("#images-carousel .slick-track").clientHeight
        }px`;
      }, 500);
    }
  };

  useEffect(() => {
    adjustBlogCarousel();
    window.addEventListener(
      "resize",
      () => {
        adjustBlogCarousel();
      },
      true,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <IndexPageTemplate
        // title={frontmatter.title}
        // subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        mainpitch {
          title
          description
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            linkTitle
          }
        }
      }
    }
  }
`;
